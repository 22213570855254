import { isBrowser } from '../utils'

const ChallengeRedirect = ({ location: { pathname } }) => {
  if (isBrowser) {
    window.parent.location.href = pathname.replace('redirect', 'thanks')
    window.history.back()
  }
  return null
}

export default ChallengeRedirect
